import React, { useState, useRef, useMemo, ReactElement, cloneElement, ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { GRID_CHECKBOX_SELECTION_COL_DEF as Column, GridSelectionModel, GridSortItem, GridSortModel, GridColDef } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button, IconButton } from '_shared/buttons'
import FloatingButton from '_shared/buttons/FloatingButton'
import FloatingMenuButton, { IMenuAction } from '_shared/buttons/FloatingMenuButton'
import Typography from '_shared/Typography'

import { AffiliationPopover } from '_core/components/Affiliation'
import { IsPrivateRelationshipBudge, MarkPrivateDialog, MarkPublicDialog } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import Download from '_core/components/Download'
import ExpandableDetails from '_core/components/ExpandableDetails'
import { Controller as FilterController } from '_core/components/filters'
import { PeopleFiltersType } from '_core/components/filters/People'
import { DataGrid, GridTypes } from '_core/components/grid'
import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import { Middle, Narrow, useWide, Wide, WideStrict } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SearchInput from '_core/components/SearchInput'
import SendEmailToSelected from '_core/components/SendEmailToSelected'
import SidepanelLink from '_core/components/SidepanelLink'
import { sortMap, getSortModels } from '_core/components/sort'
import Sort, { PeopleSortProps, Controller as SortController } from '_core/components/sort/People'
import StatsContent from '_core/components/StatsContent'
import TagsGroup from '_core/components/TagsGroup'
import Widget from '_core/components/Widget'

import useAdmin from '_core/hooks/useAdmin'
import useDialog from '_core/hooks/useDialog'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import usePeopleListPayloads, { PersonItem } from '_core/hooks/usePeopleListPayloads'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSelectedInfoHandler from '_core/hooks/useSelectedInfoHandler'
import { groupTags } from '_core/hooks/useTagsManager'

import { checkOutlook } from '_core/helpers/outlook'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles<{ filtersOpened: boolean }>()((theme, { filtersOpened }) => ({
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: filtersOpened ? 'calc(100% - 39px)' : `calc(100% - ${39 * 3}px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: filtersOpened ? 39 : 39 * 3,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  sortContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${theme.spacing(2)}`
  }
}))

type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<PeopleFiltersType, 'opened' | 'disabled' | 'toggleOpen'>
  sortProps: Pick<PeopleSortProps, 'items' | 'sort' | 'updateSort' | 'excludeEmpty' | 'toggleExclude'>
  viewProps: {
    viewMode?: ViewModeType
    updateViewMode: (val: ViewModeType) => void
  }
  searchPlaceholder: string
}

const menuActions: IMenuAction[] = [
  {
    label: 'Add person',
    icon: ['far', 'user-plus'],
    link: `${Paths._people}/add`
  },
  {
    label: 'Upload contacts',
    icon: ['fas', 'cloud-arrow-up'],
    link: `${Paths._people}/upload`
  }
]

export const GridHeadingButtons = () => {
  const admin = useAdmin()
  return (
    <>
      {admin && (
        <>
          <Wide>
            <WideStrict>
              <SidepanelLink linkProps={{ to: `/people/add` }} sidepanel={true}>
                <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />}>
                  Add Person
                </Button>
              </SidepanelLink>
              <Box ml={2}>
                <SidepanelLink linkProps={{ to: `${Paths._people}/upload` }} sidepanel={true}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={['far', 'cloud-arrow-up']} style={{ fontSize: 14 }} />}
                  >
                    Upload contacts
                  </Button>
                </SidepanelLink>
              </Box>
            </WideStrict>
            <Middle>
              <SidepanelLink linkProps={{ to: `${Paths._people}/add` }} sidepanel={true}>
                <IconButton color="primary" size="small" hint="Add person" icon={['far', 'user-plus']} />
              </SidepanelLink>
              <SidepanelLink linkProps={{ to: `${Paths._people}/upload` }} sidepanel={true}>
                <IconButton color="primary" size="small" hint="Upload contacts" icon={['far', 'cloud-arrow-up']} />
              </SidepanelLink>
            </Middle>
          </Wide>
          <Narrow>
            <FloatingMenuButton actions={menuActions} />
          </Narrow>
        </>
      )}
      {!admin && (
        <>
          <Wide>
            <SidepanelLink linkProps={{ to: `${Paths._people}/add` }} sidepanel={true}>
              <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />}>
                Add Person
              </Button>
            </SidepanelLink>
          </Wide>
          <Narrow>
            <SidepanelLink linkProps={{ to: `${Paths._people}/add` }}>
              <FloatingButton>
                <FontAwesomeIcon icon={['far', 'user-plus']} size="lg" />
              </FloatingButton>
            </SidepanelLink>
          </Narrow>
        </>
      )}
    </>
  )
}

export const Heading = ({ filtersProps, sortProps, viewProps, searchPlaceholder, filters }: HeadingProps) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { viewMode, updateViewMode } = viewProps
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, disabled } = filtersProps
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const [sortCollapsed, setSortCollapsed] = useState(true)
  const { classes } = useStyles({ filtersOpened })

  const toggleSortOpen = () => {
    setSortCollapsed((prevState: boolean) => !prevState)
  }

  const toggleView = () => updateViewMode(viewMode === 'collapsed' ? 'expanded' : 'collapsed')

  return (
    <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
      <div ref={anchorRef}>
        <Box display="flex">
          <SearchInput disabled={disabled} placeholder={searchPlaceholder} variant="collapsed" opened wrapperClassName={classes.input} />
          <Box className={classes.icons}>
            <IconButton
              hint={viewMode === 'expanded' ? 'Collapse view' : 'Expand view'}
              onClick={toggleView}
              size="small"
              icon={['fas', viewMode === 'expanded' ? 'chart-simple-horizontal' : 'bars']}
              className={classes.displayVariant}
              disabled={disabled}
            />
            <SortController collapsed={sortCollapsed} toggleCollapsed={toggleSortOpen} className={classes.displayVariant} disabled={disabled} />
            <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />
          </Box>
        </Box>
      </div>
      {cloneElement(filters, { anchorEl: anchorRef.current })}
      <Sort collapsed={sortCollapsed} {...sortProps} className={classes.sortContainer} />
    </Widget>
  )
}

export type PeopleListProps = {
  items: PersonItem[]
  loading: boolean
  enableDownloading: boolean | null
  total: number
  updateSort: (val: ScoreType | StatSortType) => void
  sort?: ScoreType | StatSortType
  setPageSize: GridTypes['setPageSize']
  paging: GridTypes['paging']
  onResize: GridTypes['onResize']
  forceLoading: boolean
  sortByField?: GridSortItem['field']
  forceNarrow?: boolean
  viewMode?: ViewModeType
  columns: GridColDef[]
  downloadAllControl: ReactElement
}

const initGridState = {
  pinnedColumns: { left: [Column.field, 'name'], right: [Column.field, 'score'] }
}

const PeopleList = (props: PeopleListProps) => {
  const upSmall = useWide('sm')
  const wide = useWide()

  const { selected, setSelected, selectedIDs, selectedNames, handleSelectedInfo, selectedUserKeys, allSelectedArePrivate, allSelectedArePublic } =
    useSelectedInfoHandler('people')
  const { queryParams } = useSearchQuery<PeoplePageParams>()
  const loading = props.loading || props.forceLoading
  const { sortByField = 'score', viewMode, enableDownloading } = props
  const { privateList, setPrivateList, withTagsList } = usePeopleListPayloads(props.items)
  const isExpandedView = viewMode === 'expanded'
  const { dialogContentProps: openedDialog, openDialog, closeDialog, successMode, openSuccess } = useDialog<'private' | 'public' | null>(null)

  const visibleStatsContent =
    !(viewMode === 'collapsed' && sortByField === 'score') && !!props.columns.find(({ field }) => field === 'lastInboundMsg')

  const items = useMemo(
    () =>
      props.items?.map((person) => {
        const isPrivateRelations = privateList?.includes(person.email)

        const tags = withTagsList?.find((item) => item.identifier === person.id)?.tags

        const showAllTagsLink = `${Paths._people}/${person.id}/tags?name=${person.name}`
        const editTagsLink = `${Paths._people}/${person.id}/tags/edit?name=${person.name}`

        return {
          ...person,
          isPrivateRelations,
          tags,
          showAllTagsLink,
          editTagsLink,
          byline3: (
            <ExpandableDetails
              isExpandedView={isExpandedView}
              collapsedSize={tags?.length ? 24 : 0}
              contentStart={
                <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                  items={tags}
                  tagComponent={InternalTagPopover}
                  renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                      items={tags ? groupTags(tags) : tags}
                    />
                  )}
                  max={upSmall ? 4 : 2}
                  title={
                    isExpandedView ? (
                      <Typography color="text.secondary" semiBold>
                        Tags
                      </Typography>
                    ) : null
                  }
                  {...(wide
                    ? {
                        addTagTriggerEl: <AddTagTriggerEl link={editTagsLink} hasAny={!!tags?.length} />
                      }
                    : {})}
                  showIcon={isExpandedView}
                />
              }
              content={
                visibleStatsContent && (
                  <StatsContent
                    lastInbound={person.lastInbound}
                    lastOutbound={person.lastOutbound}
                    lastMeeting={person.lastMeeting}
                    nextFutureMeeting={person.nextFutureMeeting}
                    isExpandedView={viewMode === 'expanded'}
                    sortByField={sortByField}
                  />
                )
              }
            />
          ),
          icons: (
            <Box display="flex" alignItems="center" justifyContent="end" mr={0.5}>
              {person.formerJob && (
                <Box ml={1.5}>
                  <AffiliationPopover workExperience={person.workExperience} icon={['far', 'address-book']} />
                </Box>
              )}
              {isPrivateRelations && <IsPrivateRelationshipBudge userName={person.name} />}
            </Box>
          )
        }
      }),
    [props.items, viewMode, sortByField, withTagsList, privateList, visibleStatsContent, upSmall]
  )

  const removePrivate = () => {
    setPrivateList((prevState: any) => prevState.filter((key: any) => !selectedUserKeys.includes(key)))
    setSelected((prevState: any) => prevState.map((person: any) => ({ ...person, isPrivateRelations: false })))
  }

  const addPrivate = () => {
    setPrivateList((prevState: any) => [...prevState, ...selectedUserKeys])
    setSelected((prevState: any) => prevState.map((person: any) => ({ ...person, isPrivateRelations: true })))
  }

  const updateSort = (model: GridSortModel) => {
    if (model?.length && queryParams.sort) {
      const { field, sort: newSort } = model[0]
      if (newSort && queryParams.sort !== sortMap[field][newSort]) {
        const sort = sortMap[field][newSort]
        props.updateSort(sort)
      }
    }
  }

  const handleSelect = (selectionModel: GridSelectionModel) => {
    handleSelectedInfo(items, selectionModel as string[])
  }

  const DownloadSelected = (
    <Download disabled={!selected.length} leadsList={selectedIDs.map((el: string) => el.replaceAll('%27', "'"))} leadType="Person" />
  )

  const handlePrivateOpen = () => {
    openDialog('private')
  }

  const handlePublicOpen = () => {
    openDialog('public')
  }

  const MarkSelectedAsPrivate = (
    <MarkPrivateDialog.TriggerIcon
      style={{ fontSize: 18, maxWidth: 42 }}
      disabled={!selected.length || allSelectedArePrivate}
      hint="Mark selected relationships as private"
      onClick={handlePrivateOpen}
    />
  )

  const MarkSelectedAsPublic = (
    <MarkPublicDialog.TriggerIcon
      style={{ fontSize: 18, maxWidth: 42 }}
      disabled={!selected.length || allSelectedArePublic}
      hint="Mark selected relationships as shared"
      onClick={handlePublicOpen}
    />
  )

  const SendEmail = <SendEmailToSelected disabled={!selectedUserKeys.length} recipients={selectedUserKeys} />

  const AddTags = (
    <SidepanelLink
      linkProps={{
        to: selected.length
          ? selected.length === 1
            ? mergeUrlWithParams(`${Paths._people}/${selectedIDs[0]}/tags/edit`, {
                name: selectedNames?.join(', ')
              })
            : mergeUrlWithParams(`${Paths._people}/add-tags`, {
                keys: selectedIDs?.join(','),
                names: selectedNames?.join(', ')
              })
          : ''
      }}
      sidepanel={true}
    >
      <IconButton
        disabled={!selected.length}
        icon={['far', 'tag']}
        style={{ fontSize: 21, maxWidth: 42, maxHeight: 42 }}
        color="primary"
        hint={`${selected.length === 1 ? 'Edit' : 'Add'} tags`}
      />
    </SidepanelLink>
  )

  const Merge = (
    <SidepanelLink
      linkProps={{
        to: selected.length
          ? mergeUrlWithParams(`${Paths._merge}/people`, {
              ids: selectedIDs?.join(',')
            })
          : ''
      }}
      sidepanel={true}
    >
      <IconButton
        disabled={!selected.length}
        icon={['far', 'merge']}
        style={{ fontSize: 18, maxWidth: 42, maxHeight: 42 }}
        color="primary"
        hint={`Merge ${selected.length === 1 ? '' : 'people'}`}
      />
    </SidepanelLink>
  )

  const controls =
    typeof enableDownloading === 'boolean'
      ? [
          { control: props.downloadAllControl, condition: enableDownloading },
          { control: DownloadSelected, condition: enableDownloading },
          { control: AddTags },
          { control: MarkSelectedAsPrivate },
          { control: MarkSelectedAsPublic },
          { control: SendEmail },
          { control: Merge }
        ]
          .filter((item) => (typeof item.condition === 'boolean' ? item.condition : true))
          .map((item) => item.control)
      : []

  const models: GridSortModel = useMemo(() => getSortModels(props.sort), [props.sort])

  const itemVariant = isExpandedView ? 'card' : 'list'

  return (
    <>
      <Wide forceNarrow={props.forceNarrow}>
        <DataGrid
          rows={!loading ? items : []}
          columns={props.columns}
          controls={controls}
          setSortModel={updateSort}
          sortModel={models.filter((m) => m.field === sortByField)}
          onSelect={handleSelect}
          loading={loading}
          setPageSize={props.setPageSize}
          onResize={props.onResize}
          checkboxSelection={!!controls.length}
          paging={props.paging}
          total={props.total}
          initialState={initGridState}
        />
      </Wide>
      <Narrow forceNarrow={props.forceNarrow}>
        <Repeater direction="vertical" variant={itemVariant} component={ProfileItem} skeleton={{ size: 20, loading }} items={!loading ? items : []} />
      </Narrow>
      <MarkPrivateDialog
        opened={openedDialog === 'private'}
        success={successMode}
        openSuccess={openSuccess}
        close={closeDialog}
        userKeys={selectedUserKeys}
        update={addPrivate}
      />
      <MarkPublicDialog
        opened={openedDialog === 'public'}
        success={successMode}
        openSuccess={openSuccess}
        close={closeDialog}
        userKeys={selectedUserKeys}
        update={removePrivate}
      />
    </>
  )
}

export default PeopleList
