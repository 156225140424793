import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { TeamContext } from '_core/context/TeamContext'

import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { stringifyUrl } from '_core/helpers/browser'

import { post } from 'utils/httpUtils'
import { getLocal, dateFormatURLQuery } from 'utils/Utils'

import Paths from 'Paths'

export const getDateRange = (from: string, to: string, sort: Required<Pick<EntityActivitiesPageParams, 'sort'>>['sort']) => {
  const fromDate = getLocal(from, dateFormatURLQuery)
  const toDate = getLocal(to, dateFormatURLQuery)

  const result = []
  const isAscending = sort === 'OldestToNewest'
  const current = isAscending ? fromDate.clone() : toDate.clone()

  while (isAscending ? current.isSameOrBefore(toDate, 'month') : current.isSameOrAfter(fromDate, 'month')) {
    const year = current.year()
    const month = current.month() + 1
    const minDay = current.isSame(fromDate, 'month') ? current.date() : 1
    const maxDay = current.isSame(toDate, 'month') ? toDate.date() : current.clone().endOf('month').date()

    result.push({ year, month, minDay, maxDay })
    current.add(isAscending ? 1 : -1, 'month').startOf('month')
  }

  return result
}

const useCompanyActivities = (isReady: boolean, companyId: string, contributorId?: string) => {
  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { UserKeyMd5, UserKey } = userKeyResult?.results || {}
  const { teamContextValue } = useContext(TeamContext)

  const userCompany = UserKey?.split('@')[1]

  const { queryParams } = useSearchQuery<EntityActivitiesPageParams>()
  const { interaction, from, to, sort } = queryParams

  const [{ emails, meetings, loading }, setData] = useState<{
    emails?: ActivityEmailsRespType[]
    meetings?: ActivityMeetingsRespType[]
    loading: boolean
  }>({ loading: false })
  const [reqIndex, setReqIndex] = useState(0)

  const dateRange = useMemo(() => (sort ? getDateRange(from, to, sort) : []), [from, to, sort])

  const more = useCallback(() => {
    if (reqIndex < dateRange.length - 1) {
      setReqIndex((prevReqIndex) => prevReqIndex + 1)
    } else {
      setData(({ emails = [], meetings = [] }) => ({ emails, meetings, loading: false }))
    }
  }, [dateRange.length, reqIndex])

  const reload = () => {
    setData({ loading: false })
    setReqIndex(0)
  }

  const payload = useMemo(
    () => ({
      direction: sort,
      skip: 0,
      take: 10000,
      participantTake: 10000,
      withMeetingTags: false,
      havingMeetingTags: '',
      lackingMeetingTags: ''
    }),
    [sort]
  )

  const interactionPayloads = useCompanyActivitiesPayloads(companyId, contributorId)

  useEffect(() => {
    // think about: request chunks in scope on month

    const makeRequest = async (plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } }) => {
      const { payload: interactionPld } = [
        {
          payload: plds.meetings,
          condition: !interaction || interaction === 'Meeting'
        },
        {
          payload: plds.outbound,
          condition: interaction === 'Outbound'
        },
        {
          payload: plds.inbound,
          condition: interaction === 'Inbound'
        }
      ].find(({ condition }) => condition) || { payload: {} }

      setData((prevState) => ({ ...prevState, loading: true }))
      const dateRangePayload = dateRange[reqIndex]

      const emailsReq = () =>
        post<{ data: ActivityEmailsRespType[]; total_item_count: number }>(`/emails/bymonth?teamNumber=${teamContextValue.teamNumber}`, {
          ...dateRangePayload,
          ...payload,
          ...interactionPld
        })

      const meetingsReq = () =>
        post<{ data: ActivityMeetingsRespType[]; total_item_count: number }>(`/meetings/bymonth?teamNumber=${teamContextValue.teamNumber}`, {
          ...dateRangePayload,
          ...payload,
          ...interactionPld
        })

      const fakePromiseRes = { data: [], total_item_count: 0 }
      const fakePromise = () => new Promise<typeof fakePromiseRes>((res) => res(fakePromiseRes))

      const [emailsData, meetingsData] = await Promise.all([
        (!interaction || interaction !== 'Meeting' ? emailsReq : fakePromise)(),
        (!interaction || interaction === 'Meeting' ? meetingsReq : fakePromise)()
      ])

      if (emailsData && meetingsData) {
        if (!emailsData.total_item_count && !meetingsData.total_item_count) {
          more()
        } else {
          setData(({ meetings = [], emails = [] }) => ({
            loading: false,
            emails: [...emails, ...emailsData.data],
            meetings: [...meetings, ...meetingsData.data]
          }))
        }
      }
    }

    if (isReady && interactionPayloads) {
      makeRequest(interactionPayloads)
    }
  }, [dateRange, interaction, isReady, more, payload, reqIndex, teamContextValue.teamNumber, companyId, interactionPayloads])

  const participantsMapper = ({
    StandardizedName: name,
    AddressText: email,
    EmailMd5: id,
    UrlMd5: md5
  }: ActivityEmailsRespType['Participants']['data'][number]) => ({
    id,
    name: name || email || md5, // Ask some participants name and email are missed, should I use md5 instead,
    email: email || '', // Ask some participants emails are missed,
    companyMd5: md5
  })

  const mtgs = meetings?.map(({ StartDateTime, Participants, CalGlobalKey }) => {
    const isFutureEvent = getLocal(StartDateTime).isAfter(getLocal())

    const companies = Participants.data.reduce((acc, { AddressText: email }) => {
      const companyDomain = email?.split('@')[1]
      return companyDomain && !acc.includes(companyDomain) ? [...acc, companyDomain] : acc
    }, [] as string[])

    const isParticipant = Participants.data.findIndex(({ EmailMd5 }) => EmailMd5 === UserKeyMd5) > -1

    const { pathname, params }: { pathname: string; params: Modify<ActivityEventPageParams, { participants?: string[]; companies?: string[] }> } =
      isParticipant
        ? { pathname: `${Paths._events}/${CalGlobalKey}`, params: { idType: 'CalGlobalKey' } }
        : {
            pathname: `${Paths._activities}/event`,
            params: {
              participants: Participants.data.map((p) => p.AddressText || p.EmailMd5),
              companies: companies,
              startTime: StartDateTime
            }
          }

    const from = Participants.data.filter(({ Role }) => Role === 'From').map(participantsMapper)
    const to = Participants.data.filter(({ Role }) => Role !== 'From').map(participantsMapper)

    return {
      from,
      to,
      companies: companies,
      dateTime: StartDateTime,
      actionText: isFutureEvent ? 'will meet' : 'met',
      icon: ['far', 'calendar-alt'] as IconProp,
      isInternal: companies.length === 1,
      detailsLink: stringifyUrl(pathname, params) // Check whether we can fetch by id,
    }
  })

  const emls = emails?.map(({ ExactSubmitTime, Participants }) => {
    const companies = Participants.data.reduce((acc, { AddressText: email }) => {
      const companyDomain = email?.split('@')[1]
      return companyDomain && !acc.includes(companyDomain) ? [...acc, companyDomain] : acc
    }, [] as string[])

    const from = Participants.data.filter(({ Role }) => Role === 'From').map(participantsMapper)
    const to = Participants.data.filter(({ Role }) => Role !== 'From').map(participantsMapper)

    const isOutbound = from.find(({ companyMd5 }) => companyMd5 === userCompany)

    const { pathname, params }: { pathname: string; params?: Modify<ActivityMessagePageParams, { participants?: string[]; companies?: string[] }> } =
      {
        pathname: `${Paths._activities}/message`,
        params: {
          participants: Participants.data.map((p) => p.AddressText || p.EmailMd5),
          companies: companies,
          startTime: ExactSubmitTime
        }
      }

    const { f, t, icon, text } =
      [
        {
          f: to,
          t: from,
          icon: ['fas', 'message-arrow-down'] as IconProp,
          text: 'received message from',
          condition: !isOutbound
        },
        {
          f: from,
          t: to,
          icon: ['fas', 'message-arrow-up'] as IconProp,
          text: 'sent message to',
          condition: isOutbound
        }
      ].find(({ condition }) => condition) || {}

    return {
      from: f,
      to: t,
      companies: companies,
      dateTime: ExactSubmitTime,
      actionText: text,
      icon,
      isInternal: companies.length === 1,
      detailsLink: stringifyUrl(pathname, params) // Check whether we can fetch by id,
    }
  })

  const activities =
    emls && mtgs
      ? [...emls, ...mtgs].sort((a, b) =>
          sort === 'OldestToNewest' ? getLocal(a.dateTime).diff(getLocal(b.dateTime)) : getLocal(b.dateTime).diff(getLocal(a.dateTime))
        )
      : undefined

  return { loading, activities, hasMore: reqIndex < dateRange.length - 1, more, reload }
}

export default useCompanyActivities
