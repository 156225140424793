import { useEffect } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useIntroductionsAccess from '_core/hooks/useIntroductionsAccess'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'

export type ConfigType = { introductions: boolean; activityStream: boolean }

export const usePrivateFeaturesData = () => {
  const { fetchWithAbort, reset, result: config, setResult } = useAbortableFetch<ConfigType>()

  const checkPrivateFeatures = async () => {
    reset()
    const config = await fetchWithAbort({ url: '/app/controlledfeatures' })

    if (config?.introductions) {
      getUserIntroductionsAccess()
    }
    if (config?.activityStream) {
      getUserActivitiesAccess()
    }
  }

  const { isUserIntroductionsVisible, introductionsAccessResult, getUserIntroductionsAccess, updateUserIntroductionsAccess } =
    useIntroductionsAccess()
  const { userActivitiesAccess, activitiesAccessResult, getUserActivitiesAccess, updateUserActivitiesAccess } = useActivitiesAccess()
  const loading =
    !!config &&
    ((config.activityStream && typeof userActivitiesAccess === 'undefined') ||
      (config.introductions && typeof isUserIntroductionsVisible === 'undefined'))

  const settings = {
    loading,
    activitiesStreamEnabled: config?.activityStream,
    introductionsEnabled: config?.introductions,
    introductionsAccessResult,
    activitiesAccessResult,
    isUserIntroductionsVisible,
    userActivitiesAccess,
    setPrivateFeatures: setResult,
    updateUserIntroductionsAccess,
    updateUserActivitiesAccess,
    checkPrivateFeatures
  }

  return settings
}

const usePrivateFeatures = (refetchDeps: string[] = []) => {
  const { updateUserActivitiesAccess, updateUserIntroductionsAccess, setPrivateFeatures } = useNavBarMenuItemsShown()
  const { checkPrivateFeatures, ...privateFeatures } = usePrivateFeaturesData()

  useEffect(() => {
    checkPrivateFeatures()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refetchDeps)

  useEffect(() => {
    const { loading, activitiesStreamEnabled, introductionsEnabled, introductionsAccessResult, activitiesAccessResult } = privateFeatures
    if (!loading && typeof activitiesStreamEnabled === 'boolean' && typeof introductionsEnabled === 'boolean') {
      setPrivateFeatures({ activityStream: activitiesStreamEnabled, introductions: introductionsEnabled })
      if (activitiesAccessResult) {
        updateUserActivitiesAccess(activitiesAccessResult)
      }
      if (introductionsAccessResult) {
        updateUserIntroductionsAccess(introductionsAccessResult)
      }
    }

    return () => {
      if (typeof activitiesStreamEnabled === 'boolean' && typeof introductionsEnabled === 'boolean') {
        setPrivateFeatures({ activityStream: activitiesStreamEnabled, introductions: introductionsEnabled })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateFeatures.loading])

  return privateFeatures
}

export default usePrivateFeatures
