import { useContext, useState, useEffect, useMemo } from 'react'

import { Box } from '@mui/material'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import {
  DataAnalyticsSettings,
  DataProcessingSettings,
  DataVisibilitySettings,
  EmailAlertsSettings,
  GeneralSettings,
  LinkedInSettings,
  MarketDataSettings,
  SalesforceSettings,
  IntroductionsSettings,
  ActivitiesSettings
} from '_core/components/settings/admin'
import SettingsAccordion from '_core/components/SettingsAccordion'
import Widget from '_core/components/Widget'

import useAdmin from '_core/hooks/useAdmin'
import usePrivateFeatures from '_core/hooks/usePrivateFeatures'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'
import TabSet from 'SharedComponents/TabSet'

export const AdminSettings = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { activitiesStreamEnabled, introductionsEnabled } = usePrivateFeatures()
  const [tabIndex, setTabIndex] = useState(0)
  const admin = useAdmin()

  useEffect(() => {
    setMobileHeader('Admin settings')
  }, [setMobileHeader])

  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue)
  }

  const dataProcessingUrls = useMemo(
    () => [
      { key: 'hybridMailbox', url: '/adminsettings/dataindexing' },
      { key: 'filters', url: '/adminsettings/push' }
    ],
    []
  )

  const marketDataUrls = useMemo(
    () => [
      { key: 'marketDataIntegration', url: '/adminsettings/marketDataIntegration' },
      { key: 'dotAlignCloudHub', url: '/adminsettings/dotAlignCloudHub' }
    ],
    []
  )

  const introductionsUrls = useMemo(
    () => [
      { key: 'list', url: '/prospecting/managers?role=ProspectingManager' },
      { key: 'defaultManager', url: '/prospecting/defaultmanager?role=ProspectingManager' },
      { key: 'settings', url: '/adminsettings/introductions' },
      { key: 'profile', url: '/me/profile' }
    ],
    []
  )

  const activitiesUrls = useMemo(
    () => [
      { key: 'settings', url: '/adminsettings/activities' },
      { key: 'profile', url: '/me/profile' }
    ],
    []
  )

  const tabs = useMemo(
    () =>
      [
        {
          id: 'general',
          label: 'GENERAL',
          component: () => <DynamicEntity url={'/adminsettings/general'} component={GeneralSettings} list={true} id="admin_settings_general" />
        },
        {
          id: 'market-data',
          label: 'MARKET DATA',
          component: () => <DynamicEntity urls={marketDataUrls} component={MarketDataSettings} id="admin_settings_market-data" />
        },
        {
          id: 'email-alerts',
          label: 'EMAIL ALERTS',
          component: () => (
            <DynamicEntity
              url={`/adminsettings/email`}
              component={EmailAlertsSettings}
              empty="Email related settings not found"
              list={true}
              id="admin_settings_email"
            />
          )
        },
        {
          id: 'salesforce',
          label: 'SALESFORCE',
          component: () => (
            <DynamicEntity
              url={'/adminsettings/salesforce'}
              component={SalesforceSettings}
              empty="Salesforce related settings not found"
              list={true}
              id="admin_settings_salesforce"
            />
          )
        },
        {
          id: 'data-processing',
          label: 'DATA PROCESSING',
          component: () => <DynamicEntity urls={dataProcessingUrls} component={DataProcessingSettings} id="admin_settings_dataprocessing" />
        },
        {
          id: 'data-visibility',
          label: 'DATA VISIBILITY',
          component: DataVisibilitySettings
        },
        {
          id: 'data-analytics',
          label: 'DATA ANALYTICS',
          component: () => (
            <DynamicEntity url={'/adminsettings/dataanalytics'} component={DataAnalyticsSettings} list={true} id="admin_settings_dataanalytics" />
          )
        },
        {
          id: 'linkedin',
          label: 'LINKEDIN',
          component: () => <DynamicEntity url={'/adminsettings/linkedInUpload'} component={LinkedInSettings} list={true} id="admin_linkedin" />
        },
        {
          id: 'introductions',
          label: 'Introductions',
          condition: introductionsEnabled,
          component: () => <DynamicEntity urls={introductionsUrls} component={IntroductionsSettings} id="admin_introductions" />
        },
        {
          id: 'activities',
          label: 'Activities',
          condition: activitiesStreamEnabled,
          component: () => <DynamicEntity urls={activitiesUrls} component={ActivitiesSettings} id="admin_activities" />
        }
      ].filter((tab) => (typeof tab.condition === 'boolean' ? tab.condition : true)),
    [dataProcessingUrls, marketDataUrls, introductionsUrls, activitiesUrls, activitiesStreamEnabled, introductionsEnabled]
  )

  return (
    <Page>
      {admin && (
        <Widget scope="none">
          <Wide>
            <Box mx={5} my={3} minHeight={'calc(100vh - 215px)'}>
              <Typography variant="h1" style={{ marginBottom: 16 }}>
                Admin settings
              </Typography>
              <TabSet tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={handleTabChange} />
            </Box>
          </Wide>
          <Narrow>
            <SettingsAccordion tabs={tabs} />
          </Narrow>
        </Widget>
      )}
    </Page>
  )
}
