import React, { useEffect, useState } from 'react'

import { Container } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack'
import { Route, Switch, useLocation, useHistory, matchPath } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import ActivitiesPages from '_pages/activities'
import AnalyzeLeadsPage from '_pages/analyze-leads'
import AppsPage from '_pages/apps'
import CompaniesList from '_pages/companies'
import CompanyPage from '_pages/companies/[id]'
import CompanyAliasSources from '_pages/companies/[id]/aliasSources'
import AuditCompanyPage from '_pages/companies/[id]/audit'
import CompanyDomainSources from '_pages/companies/[id]/domainSources'
import EditCompanyPage from '_pages/companies/[id]/edit'
import CompanyTagsPage from '_pages/companies/[id]/tags'
import CompanyEditTagsPage from '_pages/companies/[id]/tags/edit'
import AddCompanyPage from '_pages/companies/add'
import CompaniesAddTagsPage from '_pages/companies/addTags'
import UploadCompaniesPage from '_pages/companies/upload'
import DataAdministrationPage from '_pages/data-administration'
import DataSourcesPage from '_pages/data-sources'
import UploadDataSourcesPage from '_pages/data-sources/upload'
import DealsPage from '_pages/deals'
import DealPage from '_pages/deals/[id]'
import UploadDealsPage from '_pages/deals/upload'
import EmailParserPage from '_pages/emailparser'
import EventsPages from '_pages/events'
import HomePage from '_pages/home'
import IntroductionsPages from '_pages/introductions'
import ManualEditsPage from '_pages/manual-edits'
import MergePage from '_pages/merge'
import MessagePage from '_pages/message'
import OutlookLogin from '_pages/outlook/login'
import PeopleList from '_pages/people'
import PersonPage from '_pages/people/[id]'
import AuditPersonPage from '_pages/people/[id]/audit'
import EditPersonPage from '_pages/people/[id]/edit'
import UserEmailSources from '_pages/people/[id]/emailSources'
import UserPhoneSources from '_pages/people/[id]/phoneSources'
import PersonTagsPage from '_pages/people/[id]/tags'
import PersonEditTagsPage from '_pages/people/[id]/tags/edit'
import AddPersonPage from '_pages/people/add'
import PeopleAddTagsPage from '_pages/people/addTags'
import UploadContactsPage from '_pages/people/upload'
import RelationshipsPage from '_pages/relationships'
import SalesForcePage from '_pages/salesforce'
import SearchPage from '_pages/search'
import { UserSettingsPage, AdminSettingsPage } from '_pages/settings'
import Sidebar, { isSidepanel } from '_pages/sidebar'
import Sidepanel from '_pages/sidepanel'
import TagsPage from '_pages/tags'
import Teams from '_pages/teams'
import TeamProfile from '_pages/teams/[id]'
import AssociationsPage from '_pages/teams/[id]/associations'
import TeamMembersList from '_pages/teams/[id]/members'
import WelcomePage from '_pages/welcome'

import GeneralSettingsProvider from '_core/context/GeneralSettings'
import NavBarMenuProvider from '_core/context/NavBarMenu'
import OnboardingProvider from '_core/context/Onboarding'
import ParamsValidationProvider from '_core/context/ParamsValidation'

import { Button } from '_shared/buttons'

import { Narrow, Wide } from '_core/components/layout'
import Onboarding from '_core/components/Onboarding'
import PrivateRoute from '_core/components/PrivateRoute'

import useAdmin from '_core/hooks/useAdmin'
import useAppHistory from '_core/hooks/useAppHistory'
import usePageTracking from '_core/hooks/usePageTracking'
import useSidebar from '_core/hooks/useSidebar'
import { getSidepanelUrl } from '_core/hooks/useSidepanel'

import { withErrors } from '_core/ErrorBoundary'
import { checkOutlook } from '_core/helpers/outlook'

import { HistoryType, LayoutContext } from 'Layout/LayoutContextProvider'
import NavBar from 'SharedComponents/NavBar'

import Paths from 'Paths'

const sidebar = isSidepanel()

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flex: 1,
    flexFlow: 'column'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    padding: '0px!important',
    [theme.breakpoints.up('md')]: {
      padding: '30px!important',
      marginTop: 0
    }
  },
  noWrap: {
    '& > div': {
      flexWrap: 'nowrap'
    },
    '& > div:not([class*="variant"])': {
      backgroundColor: '#0B3C4B'
    }
  }
}))

const getSidepanelWidth = (sidepanelUrl: string | null) => {
  const pathname = sidepanelUrl?.split('?')?.[0]
  if (matchPath(pathname || '', { path: [Paths._salesforce] })) {
    return 845
  }

  if (
    matchPath(pathname || '', {
      path: [
        Paths._merge,
        `${Paths._people}/:id/audit`,
        `${Paths._companies}/:id/audit`,
        `${Paths._people}/add-tags`,
        `${Paths._people}/:id/tags/edit`,
        `${Paths._companies}/add-tags`,
        `${Paths._companies}/:id/tags/edit`,
        Paths._tags
      ]
    })
  ) {
    return 645
  }

  return 310
}

const updateHistory = (historyToUpdate: HistoryType[], historyIndex: number, newValue: HistoryType) => {
  const history = [...historyToUpdate]
  history[historyIndex] = newValue
  return history
}

const isSidepanelOpened = () => getSidepanelUrl() !== '/blank' && isSidepanel('sidepanel')

function Layout(props: any) {
  const location = useLocation<any>()
  const { pathname, state, search } = location
  const history = useHistory()
  const { classes } = useStyles()
  const { setSearchAnchorEl, rootHistory, setSidepanelHistory, setPreviewHistory, setRootHistory, setHistoryIndex, historyIndex } =
    React.useContext(LayoutContext)
  const blockSidepanel = window.location.search.toLowerCase().indexOf('sidepanel=false') > -1
  const matchLogin = matchPath(Paths.login, { path: location.pathname })
  const matchBlank = matchPath('/blank', { path: location.pathname })
  const [sidepanelWidth, setSidepanelWidth] = useState<number>(getSidepanelWidth(null))
  const isAdmin = useAdmin()

  const { appHistory, setAppHistory } = useAppHistory()
  const { back, forward } = useSidebar()

  usePageTracking()

  const updateSidepanelWidth = (pathname: string) => {
    setSidepanelWidth(getSidepanelWidth(pathname))
  }

  useEffect(() => {
    if (appHistory.length && historyIndex > 0) {
      setAppHistory([...appHistory.slice(0, 1), ...appHistory.slice(historyIndex + 1)])
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHistory.length])

  useEffect(() => {
    setHistoryIndex(0)
  }, [appHistory.length])

  useEffect(() => {
    if (checkOutlook() || window.name === 'extension') {
      localStorage.removeItem('numAttempts')
    }
  }, [])

  useEffect(() => {
    const setInitialHistory = () => {
      if (!matchLogin && !matchBlank && !rootHistory.length) {
        setRootHistory([{ state, pathname, search }])
      }
    }

    if (!isSidepanel() && !checkOutlook()) {
      setInitialHistory()
    }
  }, [pathname, search, state, matchLogin, rootHistory, setRootHistory])

  useEffect(() => {
    const listener = props.history.listen((location: any, action: string) => {
      if (action === 'POP') {
        setHistoryIndex((prevIndex) => prevIndex + 1)
      }
      if (action === 'PUSH') {
        if (location && location.pathname && location.pathname !== '/blank' && location.pathname.indexOf('-auth') === -1) {
          const { pathname, search, state } = location
          const l = { pathname, search, state }
          if (isSidepanelOpened()) {
            setSidepanelHistory([l, ...JSON.parse(localStorage.getItem('sidepanel_history') || '[]')])
          }
          if (isSidepanel('preview')) {
            setPreviewHistory((prevState) => [l, ...prevState])
          }
          if (checkOutlook() || window.name === 'extension' || !isSidepanel()) {
            setRootHistory((prevState) => [l, ...prevState])
          }
        }
        window.scrollTo(0, 0)
      }
      if (action === 'REPLACE') {
        if (location && location.pathname && location.pathname !== '/blank' && location.pathname.indexOf('-auth') === -1) {
          const { pathname, search, state } = location
          const l = { pathname, search, state }
          if (isSidepanelOpened()) {
            const history = JSON.parse(localStorage.getItem('sidepanel_history') || '[]')
            const updated = updateHistory(history, historyIndex, l)
            setSidepanelHistory(updated)
          }
          if (isSidepanel('preview')) {
            setPreviewHistory((prevState) => updateHistory(prevState, historyIndex, l))
          }
          if (checkOutlook() || window.name === 'extension' || !isSidepanel()) {
            setRootHistory((prevState) => updateHistory(prevState, historyIndex, l))
          }
        }
      }
    })
    return () => {
      listener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyIndex])

  useEffect(() => {
    if (blockSidepanel) {
      localStorage.removeItem('sidepanel')
      setSidepanelHistory([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockSidepanel])

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)

    function receiveMessage(event: any) {
      if (event.origin === 'https://player.vimeo.com') return
      if (event.data) {
        if (event.data === 'scrollTop') {
          window.scrollTo(0, 0)
        } else if (typeof event.data === 'string' && event.data.indexOf('url:') > -1) {
          props.history.push(event.data.replace('url:', ''))
        } else if (typeof event.data === 'string' && event.data.indexOf('expand:') > -1) {
          setSearchAnchorEl(null)
          props.history.push(event.data.replace('expand:', ''))
        } else if (typeof event.data === 'string' && event.data.indexOf('sidepanel:') > -1) {
          const pathname = event.data.replace('sidepanel:', '')
          props.history.push(pathname)
        } else if (typeof event.data === 'string' && event.data.indexOf('preview:') > -1) {
          const pathname = event.data.replace('preview:', '')
          props.history.push(pathname)
          window.parent.postMessage(`preview_show:${pathname}`, '*')
        } else if (typeof event.data === 'string' && event.data.indexOf('extension_explode') > -1) {
          window.open(history.location.pathname)
        } else if (typeof event.data === 'string' && event.data.indexOf('extension_goBack') > -1) {
          back()
        } else if (typeof event.data === 'string' && event.data.indexOf('extension_goForward') > -1) {
          forward()
        } else if (typeof event.data === 'string' && event.data.indexOf('sidepanel_open') > -1) {
          const pathname = event.data.replace('sidepanel_open:', '')
          updateSidepanelWidth(pathname)
          localStorage.setItem('sidepanel', pathname)
        } else if (
          typeof event.data === 'string' &&
          !checkOutlook() &&
          event.source?.name === 'sidepanel' &&
          event.data.includes('sidepanel_link_click')
        ) {
          const pathname = event.data.replace('sidepanel_link_click:', '')
          event.source.postMessage(`sidepanel_link_success:${pathname}`, '*')
          updateSidepanelWidth(pathname)
        } else if (
          typeof event.data === 'string' &&
          !checkOutlook() &&
          event.source?.name === 'sidepanel' &&
          event.data.includes('sidepanel_navigate_click')
        ) {
          const search = event.data.replace('sidepanel_navigate_click:', '')
          const { pathname } = JSON.parse(search)
          event.source.postMessage(`sidepanel_navigate_success:${search}`, '*')
          updateSidepanelWidth(pathname)
        } else if (isSidepanel('sidepanel') && typeof event.data === 'string' && event.data.includes('sidepanel_link_success')) {
          const pathname = event.data.replace('sidepanel_link_success:', '')
          props.history.push(pathname)
        } else if (isSidepanel('sidepanel') && typeof event.data === 'string' && event.data.includes('sidepanel_navigate_success')) {
          const search = event.data.replace('sidepanel_navigate_success:', '')
          props.history.replace(JSON.parse(search))
        }
      }
    }

    return () => window.removeEventListener('message', receiveMessage, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rootHistory)])

  LicenseInfo.setLicenseKey('ad43ee5d25798a07e543c46d2eb12d99Tz03NzI5MixFPTE3Mjk3NzM3MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

  const hideNarBarPaths = [
    `${Paths._people}/add`,
    `${Paths._companies}/add`,
    `${Paths._people}/upload`,
    `${Paths._companies}/upload`,
    `${Paths.dataSources}/upload`,
    `${Paths._introductions}/create`,
    `${Paths._introductions}/report`
  ]

  return (
    <div className={classes.root}>
      <ParamsValidationProvider>
        {(sidebar || checkOutlook()) && <Sidebar />}
        <Switch>
          {!sidebar && (
            <>
              <Wide>
                <NavBar />
              </Wide>
              <Narrow>
                <Route
                  exact
                  path={[
                    Paths._home,
                    Paths._welcome,
                    Paths._teamProfile,
                    Paths._teams,
                    Paths.dataSources,
                    Paths._companies,
                    Paths._people,
                    `${Paths._people}/:id`,
                    `${Paths._companies}/:id`,
                    Paths._settings,
                    Paths._adminSettings,
                    Paths._search,
                    Paths._analyzeLeads,
                    `${Paths._events}/:eventsId`,
                    `${Paths._messages}/:messageId`,
                    Paths._apps,
                    Paths._events,
                    Paths._relationships,
                    Paths._deals,
                    Paths._introductions,
                    `${Paths._introductions}/:id`,
                    Paths._activities,
                    Paths._dataAdmin
                  ]}
                  render={(renderProps) => <>{!hideNarBarPaths.find((path) => renderProps.match.url.includes(path)) && <NavBar />}</>}
                />
              </Narrow>
            </>
          )}
        </Switch>

        <Container className={classes.container} maxWidth={matchPath(location.pathname, { path: [Paths._activities] }) ? false : 'lg'}>
          <Switch>
            <Route exact path={Paths._welcome} render={withErrors(WelcomePage)} />
            <Route path={Paths._events} render={withErrors(EventsPages)} />
            <PrivateRoute hasAccess={isAdmin} path={`${Paths._tags}/:taggableType`} render={withErrors(TagsPage)} />
            <PrivateRoute hasAccess={isAdmin} path={Paths._dataAdmin} render={withErrors(DataAdministrationPage)} />
            <Route exact path={`/outlook/login`} render={withErrors(OutlookLogin)} />
            <Route exact path={Paths._companies} render={withErrors(CompaniesList)} />
            <Route exact path={Paths._teams} render={withErrors(Teams)} />
            <Route exact path={`${Paths._teamProfile}/members`} render={withErrors(TeamMembersList)} />
            <Route path={`${Paths._teamProfile}/associations/:entity/:entityId`} render={withErrors(AssociationsPage)} />
            <Route path={Paths._teamProfile} render={withErrors(TeamProfile)} />
            <Route path={`${Paths.dataSources}/upload`} render={withErrors(UploadDataSourcesPage)} />
            <Route path={Paths.dataSources} render={withErrors(DataSourcesPage)} />
            <Route exact path={Paths._people} render={withErrors(PeopleList)} />
            <Route path={`${Paths._people}/add/:new?`} render={withErrors(AddPersonPage)} />
            <Route path={`${Paths._people}/upload`} render={withErrors(UploadContactsPage)} />
            <Route path={`${Paths._people}/add-tags`} render={withErrors(PeopleAddTagsPage)} />
            <Route exact path={`${Paths._people}/:id/phoneSources/:phoneNumber/:key?/:type?`} render={withErrors(UserPhoneSources)} />
            <Route exact path={`${Paths._people}/:id/emailSources/:emailAddress/:key?/:type?`} render={withErrors(UserEmailSources)} />
            <Route path={`${Paths._people}/:id/audit`} render={withErrors(AuditPersonPage)} />
            <Route path={`${Paths._people}/:id/edit`} render={withErrors(EditPersonPage)} />
            <Route path={`${Paths._people}/:id/tags/edit`} render={withErrors(PersonEditTagsPage)} />
            <Route path={`${Paths._people}/:id/tags`} render={withErrors(PersonTagsPage)} />
            <Route path={`${Paths._people}/:id?`} render={withErrors(PersonPage)} />
            <Route path={`${Paths._companies}/add/:new?`} render={withErrors(AddCompanyPage)} />
            <Route path={`${Paths._companies}/upload`} render={withErrors(UploadCompaniesPage)} />
            <Route path={`${Paths._companies}/add-tags`} render={withErrors(CompaniesAddTagsPage)} />
            <Route exact path={`${Paths._companies}/:id/aliasSources/:name/:key?/:type?`} render={withErrors(CompanyAliasSources)} />
            <Route exact path={`${Paths._companies}/:id/domainSources/:url/:key?/:type?`} render={withErrors(CompanyDomainSources)} />
            <Route path={`${Paths._companies}/:id/audit`} render={withErrors(AuditCompanyPage)} />
            <Route path={`${Paths._companies}/:id/edit`} render={withErrors(EditCompanyPage)} />
            <Route path={`${Paths._companies}/:id/tags/edit`} render={withErrors(CompanyEditTagsPage)} />
            <Route path={`${Paths._companies}/:id/tags`} render={withErrors(CompanyTagsPage)} />
            <Route path={`${Paths._companies}/:id?`} render={withErrors(CompanyPage)} />
            <Route path={`${Paths._relationships}/:from/:via/:to`} render={withErrors(RelationshipsPage)} />
            <Route path="/settings" render={withErrors(UserSettingsPage)} />
            <Route path="/admin-settings" render={withErrors(AdminSettingsPage)} />
            <Route path={Paths._apps} render={withErrors(AppsPage)} />
            <Route path={`${Paths._merge}/:entity`} render={withErrors(MergePage)} />
            <Route path={Paths._manualEdits} render={withErrors(ManualEditsPage)} />
            <Route exact path="/emailparser" render={withErrors(EmailParserPage)} />
            <Route path={`${Paths._salesforce}/:entity/:id/:index?`} render={withErrors(SalesForcePage)} />
            <Route path={Paths._analyzeLeads} render={withErrors(AnalyzeLeadsPage)} />
            <Route path={`${Paths._messages}/:messageId`} render={withErrors(MessagePage)} />
            <Route path={`${Paths._deals}/upload`} render={withErrors(UploadDealsPage)} />
            <Route path={`${Paths._deals}/:dealId`} render={withErrors(DealPage)} />
            <Route path={Paths._deals} render={withErrors(DealsPage)} />
            <Route path={Paths._introductions} render={withErrors(IntroductionsPages)} />
            <Route path={Paths._activities} render={withErrors(ActivitiesPages)} />
            <Route exact path={Paths._home} render={withErrors(HomePage)} />
          </Switch>
          <Route path={`${Paths._search}/:entity?`} render={withErrors(SearchPage)} />
        </Container>
      </ParamsValidationProvider>
      {window.top === window.self && (
        <Wide>
          <Sidepanel width={sidepanelWidth} updateWidth={updateSidepanelWidth} />
        </Wide>
      )}
      <Onboarding />
    </div>
  )
}

const SnackbarAction = (key: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <Button variant="text" onClick={() => closeSnackbar(key)}>
      OK
    </Button>
  )
}

const LayoutPage = (props: any) => {
  const { classes } = useStyles()

  return (
    <SnackbarProvider maxSnack={3} action={SnackbarAction} classes={{ root: classes.noWrap }}>
      <GeneralSettingsProvider>
        <OnboardingProvider>
          <NavBarMenuProvider>
            <Layout {...props} />
          </NavBarMenuProvider>
        </OnboardingProvider>
      </GeneralSettingsProvider>
    </SnackbarProvider>
  )
}

export default LayoutPage
