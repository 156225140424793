import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { ActivitiesListItem, ActivityDataType } from '_core/components/ActivitiesList'

import { getDateRange } from '_core/hooks/useCompanyActivities'

import { request } from 'utils/fetchUtils'
import { mergeUrlWithParams, transformBody } from 'utils/httpUtils'

const useActivitiesStats = (
  plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } } | null,
  groupByMd5s: string[],
  from: string | undefined,
  to: string | undefined
) => {
  const { teamContextValue } = useContext(TeamContext)
  const [{ loading, stats }, setData] = useState<{ loading: boolean; stats?: Pick<ActivitiesListItem, 'inbound' | 'outbound' | 'meetings'>[] }>({
    loading: false
  })

  const abortRef = useRef<null | AbortController>(null)

  const clearStats = () => {
    setData({ loading: false })
  }

  const getStats = useCallback(
    async (
      plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } } | null,
      groupByMd5s: string[],
      from: string | undefined,
      to: string | undefined
    ) => {
      if (plds && from && to) {
        const meetingsPayload = {
          includeMeetings: true,
          includeEmails: false,
          focusedRole: 'Any',
          ...(plds.meetings || {})
        }

        const inboundPayload = {
          includeMeetings: false,
          includeEmails: true,
          ...(plds.inbound || {})
        }

        const outboundPayload = {
          includeMeetings: false,
          includeEmails: true,
          ...(plds.outbound || {})
        }

        const payloads = [meetingsPayload, inboundPayload, outboundPayload]

        const dateRange = getDateRange(from, to, 'NewestToOldest')

        const abortController = new AbortController()
        abortRef.current = abortController

        const responses = await Promise.all(
          payloads.map((payload) =>
            request<ActivitiesHistoStatsRespType>(mergeUrlWithParams(`/histostats?teamNumber=${teamContextValue.teamNumber}`), {
              method: 'POST',
              body: transformBody({
                ...payload,
                months: dateRange,
                withMeetingTags: true,
                havingMeetingTags: '',
                lackingMeetingTags: '',
                skip: 0,
                take: 100
              }),
              ...(abortRef.current ? { signal: abortRef.current.signal } : {})
            })
          )
        )

        if (!responses.includes(undefined)) {
          const [meetings, inbound, outbound] = responses.reduce(
            (acc, data = [], idx) => {
              data.forEach(({ results }) => {
                acc[idx % payloads.length] = {
                  ...acc[idx % payloads.length],
                  ...results.reduce(
                    (dAcc, d) => {
                      const accPer3 = acc[idx % payloads.length]?.[d.groupedBy]

                      if (!accPer3) {
                        return {
                          ...dAcc,
                          [d.groupedBy]: {
                            count: d.count,
                            groupedBy: d.groupedBy
                          }
                        }
                      }

                      return {
                        ...dAcc,
                        [d.groupedBy]: {
                          count: accPer3.count + d.count,
                          groupedBy: d.groupedBy
                        }
                      }
                    },
                    {} as { [groupedBy: string]: ActivityDataType }
                  )
                }
              })
              return acc
            },
            [] as { [groupedBy: string]: ActivityDataType }[]
          )

          const result = groupByMd5s.map((key) => ({
            meetings: meetings[key],
            inbound: inbound[key],
            outbound: outbound[key]
          })) as ActivitiesListItem[]

          return result
        }
      }
    },
    [teamContextValue.teamNumber]
  )

  useEffect(() => {
    return () => {
      clearStats()
      if (abortRef.current) {
        abortRef.current.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to])

  useEffect(() => {
    if (plds && groupByMd5s.length) {
      ;(async () => {
        setData({ loading: true })
        const stats = await getStats(plds, groupByMd5s, from, to)
        if (stats) {
          setData({ loading: false, stats })
        }
      })()
    }
  }, [from, getStats, groupByMd5s, plds, to])

  return {
    loading,
    stats,
    clearStats
  }
}

export default useActivitiesStats
